.container {
  display: flex;
  margin-top: 52px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.imgPlaceholder {
  width: 100vw;
  height: 220px;
  background: url(../../../assets/bg_rect_svg.932a0ff9.svg);
  background-repeat: repeat;
  cursor: pointer;
}

.container span {
  margin-top: 35px;
}

.fileInput {
  display: none;
}

.fileLabel {
  margin-top: 35px;
  background-color: rgb(18, 19, 50);
  color: rgb(197, 198, 212);
  padding: 8px 12px 8px 12px;
  border: 1px solid rgb(20, 30, 90);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 35px;
}
