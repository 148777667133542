/*
  TODO move to globals after Parcel 2 shiped with postcss-import support
  https://github.com/postcss/postcss-import
*/
@custom-media --tablet (min-width: 768px);

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.wrapper {
  padding: 12px 16px;
  margin-top: 30px;
}

.logo {
  margin-top: 24px;
  margin-bottom: 8px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  color: #333;
  font-size: 21px;
}

.subtitle {
  font-size: 12px;
  margin-top: 4px;
}

.text {
  margin-bottom: 28px;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  margin: 10px 0 10px;
}

@media (--tablet) {
  .container {
    max-width: 400px;
    margin: auto;
  }

  .buttons {
    border-radius: 4px;
    padding: 20px 25px;
  }

  .background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    right: 0;
  }

  .wrapper {
    position: relative;
    margin-top: 56px;
  }
}
