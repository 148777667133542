:root {
  --font-size: 20px;
  --font-size-md: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;

  --color-primary: rgb(256, 67, 70);
  --color-secondary: rgb(125, 76, 219);
  --color-dark: hsl(0, 0%, 16%);

  --color-text: var(--color-dark);

  --font-family: "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  background-color: #f9fafc;
  color: var(--color-text);
  font-size: var(--font-size);
  line-height: calc(var(--font-size) * 1.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

html,
body,
:global(.app) {
  all: initial; /* https://preset-env.cssdb.org/features#all-property */
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

a,
div {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

body :any-link {
  color: var(--color-secondary);
  text-decoration: none;
}

button {
  all: initial;
  font-family: var(--font-family);
  align-items: baseline;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  cursor: default;
  vertical-align: middle;
  width: 100%;
  font-weight: bold;
  color: rgb(80, 95, 121);
  height: 40px;
  line-height: 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
  border-width: 0px;
  text-decoration: none;
  border-radius: 3px;
  padding: 0px 8px;
  transition: background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  outline: none;
  background: rgb(255, 255, 255);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

h1,
h2 {
  font-weight: bold;
  font-size: 1em;
}

h3,
h4,
h5,
h6 {
  font-size: var(--font-size-md);
}

span {
  display: inline-block;
  font-size: var(--font-size-sm);
  line-height: var(--font-size);
}
