.container {
  display: flex;
  margin-top: 56px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}

.img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.colorsContainer div {
}
