.container {
  padding: 1rem;
  padding-top: 80px;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.palettes {
}

.palette {
  color: inherit;
  transition: 0.3s;
  margin-bottom: 2rem;

  &:hover {
    transform: translateY(-5px);
    transition: 0.3s;
  }
}

.paletteImage {
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.paletteTitle {
  color: var(--color-dark);
}

.colorsBlock {
  display: flex;
  height: 37px;
  justify-content: space-evenly;
}

.color {
  height: 15px;
  width: 10000px;
  border-radius: 50px;
}
