.navigationBar {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(81, 107, 152, 0.16);
}

.navigationBarWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: #fff;
}

.logo {
  width: 20px;
  height: 20px;

  & > svg {
    transform: scale(0.4) translateX(-92px) translateY(-64px);
  }
}

.rightSection {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.menuItem {
  margin-left: 1rem;
}
