.button {
  font-family: var(--font-family);
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: 10px;

  & > span {
    font-weight: 600;
    width: 100%;

    & > span:last-child {
      transform: translateX(-11px) translateY(1px);
    }
  }
}
