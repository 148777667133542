.container {
  display: flex;
}

.username {
  margin-top: 2px;
}

.avatar {
  margin-left: 12px;
}
